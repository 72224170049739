// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAq2wCJIxWPmH33LYWOAd8qb9jVuo7FMEo",
  authDomain: "talukder-website.firebaseapp.com",
  projectId: "talukder-website",
  storageBucket: "talukder-website.firebasestorage.app",
  messagingSenderId: "146126912415",
  appId: "1:146126912415:web:83201bed4e30d0626cdbaa"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);